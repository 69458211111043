import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"

export default function Home() {
  const display = { display: "none" }

  return (
    <Layout 
    isFrontpage="true"
    bodyClass="home"
    >
      <section className="home-about-services">
        <Container>
          <Row className="home-about">
            <Col sm={12} md={6}>
              <img src="/images/espa.jpg" alt="espa logo" />
              <h2>We are REDI to satisfy your needs</h2>
              <p>
                REDi has a wide international network in both practice and
                academia that can ensure the successful delivery of
                resource-demanding projects.
              </p>
              <Link style={display} className="arrow-button" to="/about">
                <span>ABOUT US</span>
              </Link>
            </Col>
            <Col sm={12} md={6}>
              <div className="stat">
                <span>200+</span>
                <p>Structural engineering worldwide projects.</p>
              </div>
              <div className="stat">
                <span>50+</span>
                <p>EU funded research programs.</p>
              </div>
            </Col>
          </Row>
          <div className="vertical">ENGINEERING SOLUTIONS</div>
          <Row className="home-service">
            <Col sm={12} md={6}>
              <Link to="/services.html">
                <img src="/images/consultancies.jpg" alt="consultancy ser" />
              </Link>
            </Col>
            <Col sm={12} md={6}>
              <div className="service-wrapper">
                <h3>Engineering Consultancies</h3>
                <div className="description">
                  <p>
                    Including Structural Design, Assessment of Structures,
                    Structural Verification for Construction Projects, Retrofit
                    Solutions, Forensic Engineering, Seismic Isolation, Exposure
                    and Vulnerability Assessment, Resilience and Risk
                    Assessment, Climate Change Adaptation techniques, etc.
                  </p>
                </div>
                <Link className="arrow-button" to="/services.html">
                  <span>LEARN MORE</span>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="home-service">
            <Col sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <div className="service-wrapper">
                <h3>Software Development</h3>
                <div className="description">
                  <p>
                    Including the development of stand-alone tailor-made
                    engineering software, add-ons and extensions to existing
                    engineering software, online Platforms, app Development,
                    etc. exploiting new technologies eg. Artificial
                    Intelligence, Big Data, IoT.
                  </p>
                </div>
                <Link className="arrow-button" to="/services.html">
                  <span>LEARN MORE</span>
                </Link>
              </div>
            </Col>
            <Col sm={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              <Link to="/services.html">
                <img
                  src="/images/software-development.jpg"
                  alt="software development"
                />
              </Link>
            </Col>
          </Row>
          <Row className="home-service">
            <Col sm={12} md={6}>
              <Link to="/services.html">
                <img src="/images/research.jpg" alt="services research" />
              </Link>
            </Col>
            <Col sm={12} md={6}>
              <div className="service-wrapper">
                <h3>Research &amp; Innovation</h3>
                <div className="description">
                  <p>
                    Establishing partnerships with Research Organisations and
                    Industry for the development of innovative, high quality
                    technical solutions from academic research to conceptual
                    design and business integration.
                  </p>
                </div>
                <Link className="arrow-button" to="/services.html">
                  <span>LEARN MORE</span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="home-projects">
        <Container className="home-projects-header">
          <Row>
            <Col sm={6}>
              <h2>Projects</h2>
            </Col>
            <Col sm={6}>
              <Link
                style={display}
                className="arrow-button arrow-white"
                to="/projects"
              >
                <span>OUR LATEST PROJECTS</span>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col sm={6} md={6} lg={3}>
              <div className="projects-image">
                {/* TODO: Link this */}
                <Link to="/projects">
                  <img src="/images/buildings.jpg" alt="projects building" />
                </Link>
              </div>
              <div className="projects-title">
                <Link to="#">
                  <h3>Buildings</h3>
                  <span style={display} className="fas fa-chevron-right"></span>
                </Link>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="projects-image">
                {/* TODO: Link this */}
                <Link to="/projects">
                  <img
                    src="/images/infrastructure.jpg"
                    alt="projects infrastracture"
                  />
                </Link>
              </div>
              <div className="projects-title">
                <Link to="#">
                  <h3>Infrastracture</h3>
                  <span style={display} className="fas fa-chevron-right"></span>
                </Link>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="projects-image">
                {/* TODO: Link this */}
                <Link to="/projects">
                  <img
                    src="/images/culturalheritage.jpg"
                    alt="projects cultural heritage"
                  />
                </Link>
              </div>
              <div className="projects-title">
                <Link to="#">
                  <h3>Cultural Heritage</h3>
                  <span style={display} className="fas fa-chevron-right"></span>
                </Link>
              </div>
            </Col>
            <Col sm={6} md={6} lg={3}>
              <div className="projects-image">
                {/* TODO: Link this */}
                <Link to="/projects">
                  <img
                    src="/images/software-solutions.jpg"
                    alt="projects software solutions"
                  />
                </Link>
              </div>
              <div className="projects-title">
                <Link to="#">
                  <h3>Software Solutions</h3>
                  <span style={display} className="fas fa-chevron-right"></span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
